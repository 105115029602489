<script>
// import Layout from '@/router/layouts/main';
import PenilaiLayout from "../../../layouts/penilai-layout.vue";
import appConfig from "@/app.config";
// import PageHeader from '@/components/page-header';
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    PenilaiLayout,
    Datatable,
    Pagination,
  },
  data() {
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Peserta", name: "-" },
      { width: "auto", label: "NIP", name: "-" },
      { width: "auto", label: "Jabatan", name: "-" },
      { width: "15%", label: "Status Penilaian", name: "-" },
      { width: "150px", label: "Aksi", name: "-" },
    ];
    return {
      title: "ASESEMEN HC - UNIT PELAKSANA SERTIFIKASI",
      items: [],
      isLoading: false,
      columns: columns,
      tableFilter: {
        page: 1,
        per_page: 10,
        idjadwal: "",
        idkompetensi: "",
        level: "",
        status: 1,
      },
      tableData: [],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      data_param: null,
      nama_kompetensi: "-",
      nama_level: "-",
    };
  },
  async mounted() {
    await this.getDataParam();
    await this.getData();
  },
  methods: {
    async getDataParam() {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      this.data_param = this.$route.query.peserta;
      if (
        this.data_param === null ||
        this.data_param === "" ||
        !this.$route.query.peserta
      ) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Peserta tidak ditemukan, silahkan menuju ke halaman dashboard Penilaian",
          showCancelButton: false,
          confirmButtonText: "Kembali ke Dashboard Penilaian",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({
              name: "penilai-dashboard",
            });
          }
        });
      } else {
        let get_param = CryptoJS.AES.decrypt(
          this.data_param,
          "4lfahumaNeEd5ME!"
        ).toString(CryptoJS.enc.Utf8);
        await this.getDataFromParam(get_param);
        Swal.close();
      }
    },
    getDataFromParam(param) {
      let splittedArray = param.split("_");

      this.tableFilter.idjadwal = splittedArray[0];
      this.tableFilter.idkompetensi = splittedArray[1];
      this.tableFilter.level = splittedArray[2];
      this.nama_kompetensi = splittedArray[3];
      this.nama_level = splittedArray[4];
      console.log(splittedArray);
    },
    async getData() {
      this.isLoading = true;
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "penilaian/jadwal-peserta",
          {
            params: this.tableFilter,
            headers: {
              Authorization: "Bearer " + localStorage.access_token_penilai,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data)
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta.code == 403) {
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: errResponse.meta.message,
            });
            this.$router.push({ name: "logout" });
            return false;
          }
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });

      if (data) {
        this.tableData = data.data.data;
        this.pagination.lastPage = data.data.last_page;
        this.pagination.currentPage = data.data.current_page;
        this.pagination.total = data.data.total;
        this.pagination.lastPageUrl = data.data.last_page_url;
        this.pagination.nextPageUrl = data.data.next_page_url;
        this.pagination.prevPageUrl = data.data.prev_page_url;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
        console.log(this.tableData);
      }
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    pilihPeserta(row) {
      let param_text =
        this.tableFilter.idkompetensi +
        "_" +
        this.tableFilter.level +
        "_" +
        row.jadwal_peserta_idjadwal_peserta;
      let my_param = CryptoJS.AES.encrypt(
        param_text,
        "4lfahumaNeEd5ME!"
      ).toString();

      this.$router.push({
        name: "penilai-soal-peserta",
        query: { peserta: my_param },
      });
      console.log(row);
    },
    async kembali() {
      this.$router.back();
    },
  },
};
</script>
<template>
  <PenilaiLayout>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="col-12 d-flex justify-content-between">
                <div class="card-title">Daftar Peserta</div>
                <button class="btn btn-danger btn-sm" @click="kembali()">
                  <i class="fa fa-arrow-left me-1"></i> Kembali
                </button>
              </div>
              <hr class="mt-2" />
              <div class="col-12">
                <div class="form-group row">
                  <label for="" class="form-label col-md-2">Kompetensi</label>
                  <label for="" class="form-label col-md-10"
                    >: {{ nama_kompetensi }}</label
                  >
                </div>
                <div class="form-group row">
                  <label for="" class="form-label col-md-2">Level</label>
                  <label for="" class="form-label col-md-10"
                    >: {{ nama_level }}</label
                  >
                </div>
              </div>
              <div class="col-12">
                <hr class="my-2" />
                <div class="row mb-2">
                  <div class="col-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            v-model="tableFilter.per_page"
                            @change="getData()"
                          >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-5">&nbsp;</div>
                  <div class="col-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <div class="row">
                  <Datatable :columns="columns">
                    <tbody>
                      <tr v-if="isLoading">
                        <td class="text-center" colspan="99">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="tableData == ''">
                        <td class="text-center" colspan="99">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr v-else v-for="(row, index) in tableData" :key="index">
                        <td>
                          {{ pagination.from + index }}
                        </td>
                        <td>
                          {{ row.nama || "" }}
                        </td>
                        <td>
                          {{ row.nip || "" }}
                        </td>
                        <td>
                          {{ row.jabatan_nama || "" }}
                        </td>
                        <td class="text-center">
                          <span class="badge rounded-pull bg-success" v-if="row.status_skor_kompetensi == 1">
                            Sudah Dinilai
                          </span>
                          <span class="badge rounded-pull bg-danger" v-else> Belum Dinilai </span>
                        </td>
                        <td class="text-center">
                          <div class="d-grid">
                            <button
                              class="btn btn-primary btn-sm"
                              @click="pilihPeserta(row)"
                            >
                              <i class="fa fa-check me-1"></i> Pilih
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Datatable>
                </div>
                <pagination
                  :pagination="pagination"
                  @to-page="toPage"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PenilaiLayout>
</template>

<style scoped>
.nav-head {
  max-width: 1000px !important;
}

.search-pad {
  padding-left: 5px !important;
}

.input-group {
  display: flow;
}

.search-box {
  display: flow;
  align-items: center;
  background-color: #f8f8fb;
  border-radius: 40px;
}

.search-icon {
  position: static;
  line-height: 30px;
}

.search-input input {
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: unset;
  padding: 5px 0px 5px 15px;
}

.position-relative {
  position: relative;
}

.span-notif {
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 8px;
}

.icon-notif {
  position: relative;
  top: 5px;
}

.nav-pills .nav-link {
  border-radius: 5rem;
}
</style>
