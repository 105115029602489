<script>
// import axios from "axios";
import router from "../router";

export default {
  name: "HeaderPenilai",
  methods: {
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    logout() {
      // var config_logout = {
      //   method: "post",
      //   url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/logout",
      //   headers: {
      //     Accept: "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("access_token"),
      //   },
      // };
      // axios(config_logout)
      //   .then(function (response) {
      //     console.log(response);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      localStorage.removeItem("user");
      localStorage.removeItem("session");
      localStorage.removeItem("session_username");
      localStorage.removeItem("session_name");
      localStorage.removeItem("session_path_photo");
      localStorage.removeItem("access_token");
      localStorage.removeItem("session_role");
      localStorage.removeItem("session_role_active_id");
      localStorage.removeItem("session_role_active_name");
      localStorage.removeItem("session_role_active_index");
      localStorage.removeItem("session_menu_access");
      localStorage.removeItem("reload_already");
      localStorage.removeItem("dateLogin");
      router.push("/login-penilai");
    },
  },
};
</script>

<template>
  <header
    id="page-topbar"
    class="mb-0 bg-primary text-white font-weight-semibold font-size-20"
    style="position: relative"
  >
    <div class="navbar-header">
      <div class="container">
        <div class="navbar-brand-box">
          <img
            src="@/assets/images/logo-pln.png"
            alt
            height="40"
            style="float: left"
          />
          Asesmen Hard Competency

          <button class="btn btn-sm btn-danger" @click="logout" style="float: right; vertical-align: middle">
            <i class="fa fa-sign-out-alt me-1"></i> Logout
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped></style>
